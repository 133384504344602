export default [
  { id: 'garden-banner', defaultMessage: 'Vlastní záhonek' },
  {
    id: 'garden-banner-desc',
    defaultMessage:
      'Místa máme dost a vítáni jsou jak úplní začátečníci, tak ostřílení zemědělci.',
    // 'Pro rok 2021 již máme kapacitu individuálních záhonků naplněnu.',
  },
  { id: 'garden-banner-action', defaultMessage: 'Přidejte se!' },
  { id: 'garden-renting', defaultMessage: 'Vlastní záhonek' },
  { id: 'garden-renting-how', defaultMessage: 'Základní členství obsahuje' },
  {
    id: 'garden-renting-example',
    defaultMessage:
      '<a href="https://medium.com/@ozluy/gardening-in-prague-from-zero-to-hero-ed463b4ce35" target="_blank" rel="no-opener no-referrer">Zahradní příběh z roku 2019 v Metrofarm</a>',
  },
  {
    id: 'garden-renting-how-1',
    defaultMessage: 'Cca 5 m2 pěstebního prostoru',
  },
  { id: 'garden-renting-how-2', defaultMessage: 'Zahradnické nářadí' },

  {
    id: 'garden-renting-how-3',
    defaultMessage: 'Zahradnické workshopy v průběhu celého roku 2022',
  },

  {
    id: 'garden-renting-how-4',
    defaultMessage: `Povinnost odpracovat pro zahradu 10 hodin ročně při práci na produkčním políčku, při údržbě naší komunitní zahrady nebo činností, která je tvému srdci nejbližší a pro zahradu prospěšná. 
Při práci na produkčním políčku získáš nejen nové zahradnické dovednosti, ale odměnou ti budou také naše farmářské bedýnky nebo sazenice na tvůj záhon`,
  },
  {
    id: 'garden-renting-how-5',
    defaultMessage: `Povinnost udržovat prostor okolo svého záhonku do vzdálenosti 30 cm čistý a alespoň 3 x ročně jej posekat`,
  },
  {
    id: 'garden-renting-how-6',
    defaultMessage:
      '<a style="color:white; text-decoration:underline" href="/jak-se-muzete-zapojit/#how-to">Záhonkovou listinu (získáte na dotaz)</a>',
  },

  {
    id: 'garden-renting-how-7',
    defaultMessage: `Členský příspěvek na rok 2022 je 2 500 Kč.`,
  },
  {
    id: 'garden-renting-how-7-2',
    defaultMessage: `(V případě záhonu 10 m2 je 4 000 Kč)`,
  },
  {
    id: 'garden-google-drive', 
    defaultMessage:
      '<a href="https://drive.google.com/file/d/1wg9YAUzM_UslN9Ej3UM4RZrQIx-IFgtD/view?usp=sharing" target="_blank" rel="no-opener no-referrer">Více o produkčním políčku, workshopech a možnostech zapojení</a>',
  },
  { id: 'garden-renting-how2', defaultMessage: 'Členství “bez závazků“ obsahuje:' },
  {
    id: 'garden-renting-how2-1',
    defaultMessage: 'Cca 5 m2 pěstebního prostoru',
  },
  {
    id: 'garden-renting-how2-2',
    defaultMessage: 'Zahradnické nářadí',
  },
  {
    id: 'garden-renting-how2-3',
    defaultMessage:
      'Povinnost udržovat prostor okolo svého záhonku do vzdálenosti 30 cm čistý a alespoň 3 x ročně jej posekat',
  },
  {
    id: 'garden-renting-how2-4',
    defaultMessage:
      '<a style="color:white; text-decoration:underline" href="/jak-se-muzete-zapojit/#how-to">Na dotaz získáte záhonkovou listinu</a>',
  },
  {
    id: 'garden-renting-how2-5',
    defaultMessage: `Členský příspěvek na rok 2022 je 4 000 Kč`,
  },
  {
    id: 'garden-renting-how2-5-2',
    defaultMessage: `(V případě záhonu 10 m2 je 5 500 Kč)`,
  },
  {
    id: 'garden-renting-how-7-following',
    defaultMessage: 'Vodu na zalévání si přinášíme z Vltavy',
  },
  {
    id: 'garden-renting-how-7-following-2',
    defaultMessage:
      'Na zahradě ve velkém kompostujeme. Kompost je neocenitelný pomocník při zúrodňování našich záhonků. Kompostujeme hlavně listí a trávu ze Stromovky, koňský hnůj od našich sousedů a občas nějaký bio odpad z našich zahrádek a z našich domácností',
  },
  { id: 'garden-renting-action', defaultMessage: 'Mám zájem o záhonek' },
  {
    id: 'garden-renting-contact',
    defaultMessage: 'Pro více informací kontaktujte',
  },
  {
    id: 'garden-renting-plan-map',
    defaultMessage: 'Návrh zahrady',
  },
]
