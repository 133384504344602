export default [
  {
    id: 'involve-title',
    defaultMessage: 'Join in!',
  },
  {
    id: 'involve-desc',
    defaultMessage:
      'Our garden and farm are still being built and improved on while our project is still growing. There are many possibilities to get dirty, sweaty and enjoy this Prague farming adventure. Together we take care of animals, work on common garden beds, and cultivate common premises. Every volunteer is welcome.',
  },
  {
    id: 'involve-desc-warning',
    defaultMessage:
      'For the year 2021, unfortunately all hens are adopted and all garden beds are already reserved.',
  },
  {
    id: 'involve-link',
    defaultMessage:
      'Naturally, you can create your own garden bed or adopt a hen.',
  },

  {
    id: 'involve-adopt',
    defaultMessage:
      'You can find the answers to your questions about adoption ',
  },
  {
    id: 'involve-rent',
    defaultMessage: 'You can find the answers to your questions about renting ',
  },
  {
    id: 'involve-here',
    defaultMessage: 'here',
  },

  {
    id: 'involve-hen',
    defaultMessage: 'Adopt a hen',
  },
  {
    id: 'involve-hen-action',
    defaultMessage: 'I want to adopt a hen',
  },
  {
    id: 'involve-garden',
    defaultMessage: 'Do you want your own garden bed?',
  },
  {
    id: 'involve-garden-action',
    defaultMessage: 'I want to own garden bed',
  },
  {
    id: 'involve-goat',
    defaultMessage: 'Adopt a goat',
  },
  {
    id: 'involve-goat-action',
    defaultMessage: 'I want to adopt a goat',
  },
  {
    id: 'involve-sheep',
    defaultMessage: 'Adopt a sheep',
  },
  {
    id: 'involve-sheep-action',
    defaultMessage: 'I want to adopt a sheep',
  },
]
