export default [
  {
    id: 'footer-title',
    defaultMessage: 'Kontakt',
  },
  {
    id: 'footer-contact-person',
    defaultMessage: 'Koordinátor projektu a kontaktní osoba',
  },

  {
    id: 'footer-cooperation',
    defaultMessage: 'Projekt MetroFarm je realizován',
  },
  {
    id: 'footer-cooperation-rest',
    defaultMessage: 've spolupráci zapsaných spolků',
  },

  {
    id: 'footer-partners',
    defaultMessage: 'Partneři',
  },
  {
    id: 'footer-bank-no',
    defaultMessage: 'Číslo účtu',
  },
]
