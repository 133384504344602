export default [
  {
    id: 'footer-title',
    defaultMessage: 'Contact',
  },
  {
    id: 'footer-contact-person',
    defaultMessage: 'Project coordinator and contact person',
  },

  {
    id: 'footer-cooperation',
    defaultMessage:
      'Project Metro Farm is realized in cooperation ',
  },

  {
    id: 'footer-cooperation-rest',
    defaultMessage:'with registered associations.'
  },

  {
    id: 'footer-partners',
    defaultMessage: 'Partners',
  },
  {
    id: 'footer-bank-no',
    defaultMessage: 'Bank Account Number',
  },
]
