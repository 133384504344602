// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adopce-slepice-js": () => import("./../../../src/pages/adopce-slepice.js" /* webpackChunkName: "component---src-pages-adopce-slepice-js" */),
  "component---src-pages-adopte-koz-a-ovci-js": () => import("./../../../src/pages/adopte-koz-a-ovci.js" /* webpackChunkName: "component---src-pages-adopte-koz-a-ovci-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jak-se-muzete-zapojit-js": () => import("./../../../src/pages/jak-se-muzete-zapojit.js" /* webpackChunkName: "component---src-pages-jak-se-muzete-zapojit-js" */),
  "component---src-pages-kontaktni-formular-js": () => import("./../../../src/pages/kontaktni-formular.js" /* webpackChunkName: "component---src-pages-kontaktni-formular-js" */),
  "component---src-pages-vize-and-principy-js": () => import("./../../../src/pages/vize-and-principy.js" /* webpackChunkName: "component---src-pages-vize-and-principy-js" */),
  "component---src-pages-vlastni-zahonek-js": () => import("./../../../src/pages/vlastni-zahonek.js" /* webpackChunkName: "component---src-pages-vlastni-zahonek-js" */)
}

